<template>
    <div class="add-new-department">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.departmentCreate')"
            :title="$t('messages.departmentCreate')"
            @submitPressed="submitPressed"
        />
        <resource-form :resource-object="department" :resource="$Departments" base-url="/settings/departments"
                       create-message="messages.departmentCreated" update-message="messages.departmentUpdated"
                       :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            action: null,
            department: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
